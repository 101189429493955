<template>
    <div class="logout-container">
      <!-- 加载中的圆环 -->
      <div class="spinner"></div>
      <p class="logout-text">正在登出，请稍候...</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'LogoutPage',
    async mounted() {
      try {
        // 1. 调用后端 /logout
        await this.$http.post('/logout')
        // 2. 移除本地 token
        localStorage.removeItem('token')
  
        // 3. 如果有 next 就带给 /login，否则直接 /login
        const next = this.$route.query.next
        if (next) {
          this.$router.push({ path: '/login', query: { next } })
        } else {
          this.$router.push('/login')
        }
      } catch (err) {
        console.error('登出时出错', err)
        // 如果登出失败，也可以重定向登录
        this.$router.push('/login')
      }
    }
  }
  </script>
  
  <style scoped>
  .logout-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* 让内容居中 */
    background-color: #000; /* 或其它背景色 */
  }
  
  /* 加载圆环 */
  .spinner {
    width: 50px;
    height: 50px;
    border: 6px solid rgba(255,255,255,0.2);
    border-top: 6px solid #fff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 20px;
  }
  
  .logout-text {
    color: #fff;
    font-family: 'MiSans-Light', Arial, sans-serif;
    font-size: 1.2em;
  }
  
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
  </style>