<template>
  <div class="login-container">
    <div class="left-side">
      <div class="promo-content">
        <div class="invert-container">
          <img src="/img/EMlogo-large1x.png" class="logo" alt="Logo" />
        </div>
        <h2 style="font-family: 'MiSans-Bold', Arial, sans-serif;">欢迎回来</h2>
        <p style="font-family: 'MiSans-Light', Arial, sans-serif;">
          登录 VDS Entertainment Network，尽享VDS一切服务
        </p>
      </div>
    </div>

    <div class="right-side">
      <div class="mdui-card login">
        <h2 style="font-family: 'MiSans-Bold', Arial, sans-serif;">登录</h2>
        <form @submit.prevent="handleLogin">
          <div>
            <label>用户名/手机号码/电子邮件地址:</label>
            <input type="text" v-model="username" required class="input-field" />
          </div>
          <div>
            <label>密码:</label>
            <input
              type="password"
              v-model="password"
              required
              class="input-field"
            />
          </div>
          <button type="submit">登录</button>
        </form>

        <!-- 错误信息带动画 -->
        <transition
          name="errorTransition"
          @before-enter="beforeEnterError"
          @enter="enterError"
          @before-leave="beforeLeaveError"
          @leave="leaveError"
        >
          <p v-if="error" class="error">{{ error }}</p>
        </transition>

        <p>
          没有账号？
          <router-link class="router-link" :to="registerLink">创建一个</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'  // 确保已安装 gsap: npm install gsap
/* global TencentCaptcha */

export default {
  name: 'Login-page',
  data() {
    return {
      username: '',
      password: '',
      error: ''
    }
  },
  computed: {
    registerLink() {
      const next = this.$route.query.next
      return next ? { path: '/register', query: { next } } : '/register'
    }
  },
  created() {
    const token = localStorage.getItem('token')
    if (token) {
      this.$router.push('/manage')
    }
  },
  methods: {
    handleLogin() {
    // 1) 弹出腾讯云验证码
    const captcha = new TencentCaptcha(
      '193461463', // 从腾讯云获取
      (res) => {
        if (res.ret === 0) {
          // 验证成功
          const ticket = res.ticket;
          const randStr = res.randstr;
          // 2) 再把 ticket/randStr + 用户名密码 => 后端
          this.submitLogin(ticket, randStr);
        } else {
          // 用户关闭/失败
          console.log('TCaptcha 验证失败或关闭');
        }
      }
    );
    captcha.show();
  },

  async submitLogin(ticket, randStr) {
    try {
      const response = await this.$http.post('/login', {
        username: this.username,
        password: this.password,
        captchaTicket: ticket,
        captchaRandStr: randStr
      });
      localStorage.setItem('token', response.data.token);
      const nextUrl = this.$route.query.next;
      if (nextUrl) {
        this.$router.push(decodeURIComponent(nextUrl));
      } else {
        this.$router.push('/manage');
      }
    } catch (err) {
      this.error = err.response?.data?.error || '登录失败';
    }
  },

    /* ========== 错误提示 动画钩子 (height + opacity) ========== */
    beforeEnterError(el) {
      // 进入前先把它高度和透明度都设为 0
      gsap.set(el, { height: 0, opacity: 0 })
    },
    enterError(el, done) {
      // 从 0 到真实高度 + 透明度 1
      const targetHeight = el.scrollHeight
      gsap.to(el, {
        height: targetHeight,
        opacity: 1,
        duration: 0.3,
        ease: 'power2.out',
        onComplete: () => {
          // 最后把 height 还原为 auto，适应内容
          el.style.height = 'auto'
          done()
        }
      })
    },
    beforeLeaveError(el) {
      // 离开前先锁定当前高度，防止闪跳
      el.style.height = el.scrollHeight + 'px'
    },
    leaveError(el, done) {
      // 从当前高度收回到 0，透明度减到 0
      gsap.to(el, {
        height: 0,
        opacity: 0,
        duration: 0.2,
        ease: 'power2.inOut',
        onComplete: done
      })
    }
  }
}
</script>

<style scoped>
.login-container {
  display: flex;
  height: calc(100vh - 55px);
  overflow: hidden;
  margin-top: 55px;
}

.left-side {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.right-side {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.promo-content {
  text-align: center;
  color: white;
}

.logo {
  height: 50px;
}

/* 表单控件 */
label {
  display: block;
  margin-bottom: 5px;
}

.input-field {
  width: 100%;
  height: 30px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* 错误提示: 需要 overflow:hidden 以便在 height 动画中不溢出 */
.error {
  color: red;
  margin-top: 10px;
  overflow: hidden;
}

/* 登录卡片 */
.mdui-card {
  color: white;
  padding: 20px;
  max-width: 400px;
  width: 100%;
  background-color: rgba(47, 47, 47, 0.3);
  border-radius: 15px;
}

/* 按钮 */
button[type='submit'] {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
button[type='submit']:hover {
  background-color: #0056b3;
}

/* 路由链接 */
.router-link {
  text-decoration: none;
  color: #007bff;
}
.router-link:hover {
  color: #0056b3;
}

/* 小屏幕适配 */
@media (max-width: 1000px) {
  .left-side {
    display: none;
  }
  .right-side {
    flex: 1;
    padding: 10px;
  }
  .mdui-card {
    max-width: 90%;
  }
}
</style>