// src/router/index.js

import { createRouter, createWebHistory } from 'vue-router';
import Login from '@/components/Views/Login.vue';
import Register from '@/components/Views/Register.vue';
import UserContainer from '@/components/Views/Userinfo/UserInfo.vue';
import UserDash from '@/components/Views/Userinfo/Dashboard.vue';
import EditProfile from '@/components/Views/Userinfo/EditProfile.vue';
import Authorize from '@/components/Views/Authorize.vue';
import Home from '@/components/Views/Home.vue';
import logout from '@/components/Views/Userinfo/logout.vue'

const routes = [
  { path: '/', component: Home },
  { path: '/login', component: Login },
  { path: '/register', component: Register },
  { path: '/logout', component: logout },
  {
    path: '/manage',
    component: UserContainer,
    children: [
      {
        path: 'dash',
        name: 'dash',
        component: UserDash,
      },
      {
        path: 'profile',
        name: 'edit-profile',
        component: EditProfile,
      },
      {
        path: '',
        redirect: { name: 'dash' }, // 默认重定向到 userinfo
      },
    ],
  },
  { path: '/authorize', component: Authorize, meta: { requiresAuth: true } },
  // 添加其他路由
];

const router = createRouter({
  history: createWebHistory(), // 使用 HTML5 History 模式
  routes,
});

// 全局导航守卫，检查需要认证的路由
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const token = localStorage.getItem('token');
  if (requiresAuth && !token) {
    const nextUrl = encodeURIComponent(to.fullPath);
    next({ path: '/login', query: { next: nextUrl } });
  } else {
    next();
  }
});

export default router;