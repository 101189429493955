<template>
    <div class="dashboard-container">
        <div class="dashboard-header">
            <h1>{{ greetingMessage }}</h1>
            <p v-if="hasRecommendations">为你的账户推荐：</p>
        </div>
        <transition-group name="fade-move" tag="div" class="recommendations">
            <div v-if="user && !user.nickname" key="nickname-card" class="recommendation-card">
                <h3 class="hardoff">你可以设置昵称</h3>
                <p>设置一个独特的昵称，让更多用户认识你。</p>
                <button @click="openNicknameModal" class="action-button">设置</button>
            </div>
            <div v-if="user && (!user.avatarUrl || user.avatarUrl === 'https://static.vdsentnet.com/userstatic/default_avatar.png')"
                key="avatar-card" class="recommendation-card">
                <h3 class="hardoff">你可以添加头像</h3>
                <p>你还没有添加头像，添加一个头像来个性化你的账户吧。</p>
                <button @click="triggerFileSelect" class="action-button">添加</button>
                <input type="file" ref="fileInput" accept="image/*" @change="handleImageUpload"
                    style="display: none;" />
            </div>
        </transition-group>
        <transition-group name="fade-move" tag="div" class="vds-services">
            <p>你的VDS服务：</p>
            <div class="service-card" v-for="service in services" :key="service.name">
                <h3 class="hardoff">{{ service.name }}</h3>
                <p>{{ service.description }}</p>
                <a :href="service.link" target="_blank" class="service-link">访问服务</a>
            </div>
        </transition-group>
        <transition name="modal-fade">
            <div v-if="showNicknameModal" class="modal-overlay">
                <transition name="modal-zoom">
                    <div class="modal">
                        <div class="modal-header">
                            <h3 class="hardoff" style="color: black; font-size: 25px;">设置昵称</h3>
                            <p class="close-button" @click="closeNicknameModal">&times;</p>
                        </div>
                        <input type="text" v-model="newNickname" placeholder="请输入新昵称" />
                        <button @click="setNickname" :disabled="!newNickname">确认</button>
                    </div>
                </transition>
            </div>
        </transition>
    </div>
</template>

<script>
import Compressor from 'compressorjs';

export default {
    name: 'DashboardPage',
    data() {
        return {
            user: null,
            showNicknameModal: false,
            showAvatarModal: false,
            newNickname: '',
            services: [
                { name: 'VDS.pub', description: '欢乐，正发生', link: 'https://vds.pub' },
                { name: 'LiveScope', description: '轻松看直播，处处都精彩', link: 'https://livescope.tv' },
                // { name: 'VDS CloudStream Connect', description: '聚世界，尽焦点。VDS CloudStream，满足不同企业和团队的私域直播需求', link: 'https://lsnext.vds.pub' },
            ]
        };
    },
    computed: {
        greetingMessage() {
            const hour = new Date().getHours();
            let greeting = '';
            if (hour < 12) {
                greeting = '早上好';
            } else if (hour < 18) {
                greeting = '中午好';
            } else {
                greeting = '晚上好';
            }
            return `${greeting}, ${this.user ? (this.user.nickname || this.user.username) : ''}`;
        },
        hasRecommendations() {
            return this.user && (!this.user.nickname || !this.user.avatarUrl || this.user.avatarUrl === 'https://static.vdsentnet.com/userstatic/default_avatar.png');
        }
    },
    async created() {
        try {
            const response = await this.$http.get('/userinfo');
            this.user = response.data;
        } catch (err) {
            console.error('获取用户信息失败', err);
            if (err.response && err.response.status === 401) {
                localStorage.removeItem('token');
                this.$router.push('/login');
            }
        }
    },
    methods: {
        openNicknameModal() {
            this.showNicknameModal = true;
        },
        closeNicknameModal() {
            this.showNicknameModal = false;
            this.newNickname = '';
        },
        async setNickname() {
            if (this.newNickname) {
                try {
                    await this.$http.post('/edit-profile', { nickname: this.newNickname });
                    this.user.nickname = this.newNickname;
                    this.showNicknameModal = false;
                    this.newNickname = '';
                    this.$emit('refresh-user');
                } catch (err) {
                    console.error('设置昵称失败', err);
                }
            }
        },
        openAvatarModal() {
            this.showAvatarModal = true;
        },
        closeAvatarModal() {
            this.showAvatarModal = false;
        },
        triggerFileSelect() {
            this.$refs.fileInput.click();
        },
        async handleImageUpload(event) {
            const file = event.target.files[0];
            if (!file) return;

            try {
                new Compressor(file, {
                    quality: 0.8,
                    maxWidth: 500,
                    maxHeight: 500,
                    convertSize: 0,
                    success: async (compressedFile) => {
                        const formData = new FormData();
                        formData.append('image', compressedFile, 'avatar.jpg');

                        const token = this.$store ? this.$store.state.token : localStorage.getItem('token');

                        if (!token) {
                            console.error('未找到令牌，无法进行身份验证');
                            return;
                        }

                        const response = await this.$http.post('/upload-image', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'Authorization': `Bearer ${token}`,
                            },
                        });

                        const imageUrl = response.data.imageUrl;

                        await this.$http.post('/edit-profile', {
                            avatarUrl: imageUrl,
                        });

                        this.user.avatarUrl = imageUrl;
                        this.$emit('refresh-user');
                    },
                    error(err) {
                        console.error('压缩图片时出错', err);
                    },
                });
            } catch (err) {
                console.error('上传头像失败', err);
            }
        }
    }
};
</script>

<style scoped>
.dashboard-container {
    padding: 20px;
}

.dashboard-header {
    margin-bottom: 20px;
}

h1 {
    font-family: 'MiSans-Bold', Arial, sans-serif;
    font-size: 2em;
    color: white;
}

.hardoff {
    font-family: 'MiSans-Bold', Arial, sans-serif;
    font-size: 2em;
    color: white;
}

p {
    font-family: 'MiSans-Light', Arial, sans-serif;
    font-size: 1.2em;
    color: white;
}

.recommendations {
    margin-top: 20px;
}

.recommendation-card {
    background-color: #007bff;
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    transition: transform 0.5s ease, opacity 0.5s ease;
    position: relative;
}

.recommendation-card h3 {
    margin: 0 0 10px;
    font-size: 1.5em;
    font-weight: bold;
}

.recommendation-card p {
    margin: 0 0 20px;
    font-size: 1em;
}

.action-button {
    position: absolute;
    bottom: 10px;
    right: 15px;
    background-color: #0056b3;
    color: #fff;
    padding: 8px 16px;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
    border: none;
}

.action-button:hover {
    background-color: #003d80;
}

.fade-move-enter-active,
.fade-move-leave-active {
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.fade-move-enter-from,
.fade-move-leave-to {
    transform: translateY(-20px);
    opacity: 0;
}

.fade-move-enter-to,
.fade-move-leave-from {
    transform: translateY(0);
    opacity: 1;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    transition: opacity 0.3s ease;
}

.modal {
    background: #fff;
    padding: 30px;
    border-radius: 15px;
    position: relative;
    width: 400px;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -30px;
    margin-top: -30px
}

.close-button {
    font-size: 1.5em;
    cursor: pointer;
    color: #000;
}

.modal input[type="text"] {
    width: 99%;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.modal button {
    padding: 12px 24px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
    font-size: 1em;
}

.modal button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.modal button:hover:not(:disabled) {
    background-color: #0056b3;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: opacity 0.3s ease;
}

.modal-fade-enter-from,
.modal-fade-leave-to {
    opacity: 0;
}

.modal-zoom-enter-active,
.modal-zoom-leave-active {
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.modal-zoom-enter-from,
.modal-zoom-leave-to {
    transform: scale(0.1);
    opacity: 0;
}

.modal-zoom-enter-to,
.modal-zoom-leave-from {
    transform: scale(1);
    opacity: 1;
}

.vds-services {
    margin-top: 40px;
}

.vds-services h2 {
    font-family: 'MiSans-Bold', Arial, sans-serif;
    font-size: 1.8em;
    color: white;
    margin-bottom: 20px;
}

.service-card {
    background-color: #333333;
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    transition: transform 0.5s ease, opacity 0.5s ease;
    position: relative;
}

.service-card h3 {
    margin: 0 0 10px;
    font-size: 1.5em;
    font-weight: bold;
}

.service-card p {
    margin: 0 0 20px;
    font-size: 1em;
}

a {
    text-decoration: none;
    color: #007bff;
}

a:hover {
    color: #0056b3;
}
</style>