<template>
  <div class="register-container">
    <!-- 左侧推广内容 -->
    <div class="left-side">
      <div class="promo-content">
        <div class="invert-container">
          <img src="/img/EMlogo-large1x.png" class="logo" alt="Logo" />
        </div>
        <h2 style="font-family: 'MiSans-Bold', Arial, sans-serif;">加入我们</h2>
        <p style="font-family: 'MiSans-Light', Arial, sans-serif;">
          注册 VDS Entertainment Network，开始探索无限精彩
        </p>
      </div>
    </div>

    <!-- 右侧注册 / 验证 -->
    <div class="right-side">
      <div class="mdui-card register" ref="cardContainer">
        <h2 style="font-family: 'MiSans-Bold', Arial, sans-serif;">注册</h2>

        <!-- 通过 <transition> 来实现 两个阶段(Form) 切换的过渡动画 -->
        <transition
          name="cardTransition"
          mode="out-in"
          @before-enter="beforeEnterCard"
          @enter="enterCard"
          @before-leave="beforeLeaveCard"
          @leave="leaveCard"
        >
          <!-- 阶段一：注册表单 -->
          <div key="step1" v-if="!isVerificationStage">
            <form @submit.prevent="handleRegister">
              <!-- 用户名 -->
              <div>
                <label>用户名:</label>
                <input
                  type="text"
                  v-model="username"
                  required
                  class="input-field"
                  @input="validateUsername"
                />
                <!-- 用户名错误 -->
                <transition
                  name="errorTransition"
                  @before-enter="beforeEnterError"
                  @enter="enterError"
                  @before-leave="beforeLeaveError"
                  @leave="leaveError"
                >
                  <p v-if="usernameError" class="error">{{ usernameError }}</p>
                </transition>
              </div>

              <!-- 手机号 -->
              <div>
                <label>手机号码:</label>
                <input
                  type="text"
                  v-model="phoneNumber"
                  required
                  class="input-field"
                />
                <transition
                  name="errorTransition"
                  @before-enter="beforeEnterError"
                  @enter="enterError"
                  @before-leave="beforeLeaveError"
                  @leave="leaveError"
                >
                  <p v-if="phoneNumberError" class="error">{{ phoneNumberError }}</p>
                </transition>
              </div>

              <!-- 邮箱 -->
              <div>
                <label>电子邮件地址:</label>
                <input
                  type="email"
                  v-model="email"
                  class="input-field"
                />
                <transition
                  name="errorTransition"
                  @before-enter="beforeEnterError"
                  @enter="enterError"
                  @before-leave="beforeLeaveError"
                  @leave="leaveError"
                >
                  <p v-if="emailError" class="error">{{ emailError }}</p>
                </transition>
              </div>

              <!-- 密码 -->
              <div>
                <label>密码:</label>
                <input
                  type="password"
                  v-model="password"
                  @input="handlePasswordInput"
                  required
                  class="input-field"
                />
                <!-- 密码强度进度条 -->
                <transition
                  name="errorTransition"
                  @before-enter="beforeEnterError"
                  @enter="enterError"
                  @before-leave="beforeLeaveError"
                  @leave="leaveError"
                >
                  <div v-if="password" class="strength-bar-wrapper">
                    <div class="strength-bar-background">
                      <div class="strength-bar-fill" ref="strengthBar"></div>
                    </div>
                  </div>
                </transition>

                <!-- 密码格式错误 -->
                <transition
                  name="errorTransition"
                  @before-enter="beforeEnterError"
                  @enter="enterError"
                  @before-leave="beforeLeaveError"
                  @leave="leaveError"
                >
                  <p v-if="passwordError" class="error">{{ passwordError }}</p>
                </transition>
              </div>

              <!-- 确认密码 (展开/收起动画) -->
              <transition
                name="confirmTransition"
                @before-enter="beforeEnterConfirm"
                @enter="enterConfirm"
                @before-leave="beforeLeaveConfirm"
                @leave="leaveConfirm"
              >
                <div v-if="showConfirm" class="confirm-password">
                  <label>确认密码:</label>
                  <input
                    type="password"
                    v-model="confirmPassword"
                    required
                    class="input-field"
                  />
                  <!-- “密码不匹配”错误 也带高度+透明度动画 -->
                  <transition
                    name="errorTransition"
                    @before-enter="beforeEnterError"
                    @enter="enterError"
                    @before-leave="beforeLeaveError"
                    @leave="leaveError"
                  >
                    <p v-if="passwordMismatch" class="error">密码不匹配</p>
                  </transition>
                </div>
              </transition>

              <button type="submit" :disabled="!isFormValid">发送验证码</button>
            </form>
          </div>

          <!-- 阶段二：输入验证码 -->
          <div key="step2" v-else>
            <form @submit.prevent="handleVerifyCode">
              <div>
                <label>输入你收到的验证码:</label>
                <input
                  type="text"
                  v-model="verificationCode"
                  required
                  class="input-field"
                />
              </div>
              <button type="submit" :disabled="!verificationCode">验证并完成注册</button>
            </form>
          </div>
        </transition>

        <!-- 全局错误 & 成功提示 -->
        <transition
          name="errorTransition"
          @before-enter="beforeEnterError"
          @enter="enterError"
          @before-leave="beforeLeaveError"
          @leave="leaveError"
        >
          <p v-if="error" class="error">{{ error }}</p>
        </transition>

        <p v-if="success" class="success">{{ success }}</p>

        <p>
          已有账号？
          <router-link class="router-link" :to="loginLink">登录</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
/* global TencentCaptcha */

export default {
  name: 'RegisterPage',
  data() {
    return {
      // 用户名
      username: '',
      usernameError: '',
      // 手机号
      phoneNumber: '',
      phoneNumberError: '',
      // 邮箱
      email: '',
      emailError: '',
      // 密码
      password: '',
      passwordError: '',
      passwordStrength: 0, // 0=无,1=弱,2=中,3=强
      confirmPassword: '',
      passwordMismatch: false,

      // 头像、昵称(可选)
      avatarUrl: 'https://static.vdsentnet.com/userstatic/default_avatar.png',
      nickname: '',

      // 阶段(是否已经进入验证码阶段)
      isVerificationStage: false,
      verificationCode: '',

      // 全局提示
      error: '',
      success: '',

      // 是否显示“确认密码”输入框
      showConfirm: false
    }
  },
  computed: {
    // 登录链接
    loginLink() {
      const next = this.$route.query.next
      return next ? { path: '/login', query: { next } } : '/login'
    },
    // 是否可以提交表单
    isFormValid() {
      return (
        this.username &&
        !this.usernameError &&
        this.phoneNumber &&
        !this.phoneNumberError &&
        (!this.emailError) &&
        this.password &&
        !this.passwordError &&
        this.confirmPassword &&
        !this.passwordMismatch
      )
    },
    // 进度条宽度
    strengthWidth() {
      switch (this.passwordStrength) {
        case 1: return 33
        case 2: return 66
        case 3: return 100
        default: return 0
      }
    },
    // 进度条颜色
    strengthColor() {
      switch (this.passwordStrength) {
        case 1: return '#ff0000' // 红
        case 2: return 'orange'  // 橙
        case 3: return '#00ff00' // 绿
        default: return '#cccccc'
      }
    }
  },
  watch: {
    // 当 passwordStrength 改变时，更新进度条
    passwordStrength() {
      if (!this.$refs.strengthBar) return
      const finalWidth = this.strengthWidth + '%'
      const finalColor = this.strengthColor
      gsap.to(this.$refs.strengthBar, {
        width: finalWidth,
        backgroundColor: finalColor,
        duration: 0.3
      })
    },

    // 手机号
    phoneNumber(val) {
      if (!val) {
        this.phoneNumberError = ''
        return
      }
      const phoneRegex = /^1\d{10}$/
      if (!phoneRegex.test(val)) {
        this.phoneNumberError = '你的手机号格式不正确，目前暂仅支持 中国大陆 手机号（+86）注册。'
      } else {
        this.phoneNumberError = ''
      }
    },
    // 邮箱
    email(val) {
      if (!val) {
        this.emailError = ''
        return
      }
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (!emailRegex.test(val)) {
        this.emailError = '请输入正确的电子邮件地址'
      } else {
        this.emailError = ''
      }
    },
    // 确认密码
    confirmPassword(val) {
      this.passwordMismatch = (this.password !== val && val.length > 0)
    }
  },
  methods: {
    /* ========== 输入校验 ========== */
    validateUsername() {
      const usernameRegex = /^[a-zA-Z0-9]*$/
      if (!usernameRegex.test(this.username)) {
        this.usernameError = '用户名只能是英文字符或数字的组合。'
      } else {
        this.usernameError = ''
      }
    },
    handlePasswordInput() {
      // 显示 / 隐藏“确认密码”
      if (this.password.length > 0) {
        this.showConfirm = true
      } else {
        this.showConfirm = false
        this.confirmPassword = ''
      }
      // 密码是否与 confirm 不匹配
      this.passwordMismatch =
        this.password !== this.confirmPassword && this.confirmPassword.length > 0

      // 更宽松的规则：只要包含大小写数字，≥8位，其余字符随意
      const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/
      if (!passRegex.test(this.password)) {
        this.passwordError = '密码至少 8 位，并同时包含大写、小写字母和数字（可包含符号）'
      } else {
        this.passwordError = ''
      }
      this.computePasswordStrength()
    },
    computePasswordStrength() {
      const pw = this.password
      if (!pw) {
        this.passwordStrength = 0
        return
      }
      let score = 0
      if (pw.length >= 8) score++
      if (/[a-z]/.test(pw)) score++
      if (/[A-Z]/.test(pw)) score++
      if (/\d/.test(pw)) score++
      // 可加: if (/[^A-Za-z0-9]/.test(pw)) score++

      if (score <= 2) {
        this.passwordStrength = 1 // 弱
      } else if (score === 3) {
        this.passwordStrength = 2 // 中
      } else {
        this.passwordStrength = 3 // 强
      }
    },

    /* ========== 提交注册 ========== */
    // 阶段1: 发送手机验证码
  handleRegister() {
    const captcha = new TencentCaptcha('193461463', (res) => {
      if (res.ret === 0) {
        this.submitRegister(res.ticket, res.randstr);
      } else {
        console.log('验证码失败或关闭');
      }
    });
    captcha.show();
  },
  async submitRegister(ticket, randStr) {
    // 原先 handleRegister() 里的逻辑移到这里
    // 只多加 captchaTicket/captchaRandStr
    if (this.passwordMismatch) {
      this.error = '密码不匹配';
      return;
    }
    if (this.passwordError) {
      this.error = '密码格式不正确';
      return;
    }
    try {
      const resp = await this.$http.post('/register', {
        username: this.username,
        password: this.password,
        avatarUrl: this.avatarUrl,
        nickname: this.nickname,
        phoneNumber: this.phoneNumber,
        email: this.email,
        captchaTicket: ticket,
        captchaRandStr: randStr
      });
      this.success = resp.data.message || '验证码已发送，请查收短信';
      this.error = '';
      this.isVerificationStage = true;
    } catch (err) {
      this.error = err.response?.data?.error || '发送验证码失败';
      this.success = '';
    }
  },

    /* ========== 验证验证码 -> 完成注册 ========== */
    handleVerifyCode() {
    const captcha = new TencentCaptcha(
      '193461463',
      (res) => {
        if (res.ret === 0) {
          this.submitVerifyCode(res.ticket, res.randstr);
        } else {
          console.log('验证码失败或关闭');
        }
      }
    );
    captcha.show();
  },

  async submitVerifyCode(ticket, randStr) {
    try {
      const resp = await this.$http.post('/register/verify', {
        phoneNumber: this.phoneNumber,
        verificationCode: this.verificationCode,
        captchaTicket: ticket,
        captchaRandStr: randStr
      });
      this.success = resp.data.message || '注册成功，请登录';
      this.error = '';
      const nextUrl = this.$route.query.next;
      this.$router.push(
        nextUrl ? { path: '/login', query: { next: nextUrl } } : '/login'
      );
    } catch (err) {
      this.error = err.response?.data?.error || '验证码验证失败';
      this.success = '';
    }
  },

    /* ========== 阶段切换 (卡片) 动画钩子 ========== */
    beforeEnterCard(el) {
      gsap.set(el, { height: 0, opacity: 0 })
    },
    enterCard(el, done) {
      const finalHeight = el.scrollHeight
      gsap.to(el, {
        height: finalHeight,
        opacity: 1,
        duration: 0.5,
        ease: 'power2.out',
        onComplete: () => {
          el.style.height = 'auto'
          done()
        }
      })
    },
    beforeLeaveCard(el) {
      el.style.height = el.scrollHeight + 'px'
    },
    leaveCard(el, done) {
      gsap.to(el, {
        height: 0,
        opacity: 0,
        duration: 0.4,
        ease: 'power2.inOut',
        onComplete: done
      })
    },

    /* ========== 确认密码输入框 动画钩子 (height+opacity) ========== */
    beforeEnterConfirm(el) {
      gsap.set(el, { height: 0, opacity: 0 })
    },
    enterConfirm(el, done) {
      const targetHeight = el.scrollHeight
      gsap.to(el, {
        height: targetHeight,
        opacity: 1,
        duration: 0.3,
        ease: 'power2.out',
        onComplete: () => {
          el.style.height = 'auto'
          done()
        }
      })
    },
    beforeLeaveConfirm(el) {
      el.style.height = el.scrollHeight + 'px'
    },
    leaveConfirm(el, done) {
      gsap.to(el, {
        height: 0,
        opacity: 0,
        duration: 0.2,
        ease: 'power2.inOut',
        onComplete: done
      })
    },

    /* ========== 错误信息 动画钩子 (height+opacity) ========== */
    beforeEnterError(el) {
      gsap.set(el, { height: 0, opacity: 0 })
    },
    enterError(el, done) {
      const targetHeight = el.scrollHeight
      gsap.to(el, {
        height: targetHeight,
        opacity: 1,
        duration: 0.3,
        ease: 'power2.out',
        onComplete: () => {
          el.style.height = 'auto'
          done()
        }
      })
    },
    beforeLeaveError(el) {
      el.style.height = el.scrollHeight + 'px'
    },
    leaveError(el, done) {
      gsap.to(el, {
        height: 0,
        opacity: 0,
        duration: 0.2,
        ease: 'power2.inOut',
        onComplete: done
      })
    }
  }
}
</script>

<style scoped>
/* ========== 布局 ========== */
.register-container {
  margin-top: 55px;
  display: flex;
  height: calc(100vh - 55px);
  overflow: hidden;
}

.left-side {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.right-side {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.promo-content {
  text-align: center;
  color: white;
}

.logo {
  height: 50px;
}

.mdui-card {
  color: white;
  padding: 20px;
  max-width: 400px;
  width: 100%;
  background-color: rgba(47, 47, 47, 0.3);
  border-radius: 15px;
}

/* ========== 表单 ========== */
label {
  display: block;
  margin-bottom: 5px;
}

.input-field {
  width: 100%;
  height: 30px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* 提交按钮 */
button[type='submit'] {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
button[type='submit']:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
button[type='submit']:hover:not(:disabled) {
  background-color: #0056b3;
}

/* 路由链接 */
.router-link {
  text-decoration: none;
  color: #007bff;
}
.router-link:hover {
  color: #0056b3;
}

/* 确认密码区 (展开/收起) */
.confirm-password {
  margin-top: 10px;
  overflow: hidden;
}

/* ========== 错误 / 成功 提示 ========== */
.error {
  color: red;
  margin: 5px 0;
  overflow: hidden; /* 在height动画时不溢出 */
}
.success {
  color: green;
  margin-top: 10px;
}

/* ========== 密码强度进度条 ========== */
.strength-bar-wrapper {
  margin-bottom: 10px;
}
.strength-bar-background {
  background-color: rgba(255, 255, 255, 0.2);
  height: 6px;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
}
.strength-bar-fill {
  width: 0;
  height: 6px;
  background-color: #cccccc;
  border-radius: 3px;
  /* 用 GSAP 动画，所以不写 transition */
}

/* 小屏幕适配 */
@media (max-width: 1000px) {
  .left-side {
    display: none;
  }
  .right-side {
    flex: 1;
    padding: 10px;
  }
  .mdui-card {
    max-width: 90%;
  }
}
</style>