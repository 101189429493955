<template>
  <div class="container">
    <!-- 加载中 -->
    <div v-if="isLoading" class="loading-container">
      <div class="spinner"></div>
    </div>

    <!-- 页面内容 -->
    <div v-else class="content-container">
      <!-- Avatar / Logo 区域 -->
      <!-- 新增 ref="avatarContainer" 使其 position: relative; 用于后续插入波纹圆 -->
      <div class="avatar-container" ref="avatarContainer">
        <img
          ref="logoImg"
          :src="logoSrc"
          :class="logoClass"
          alt="LogoOrAvatar"
        />
      </div>

      <!-- 未登录：Slogan -->
      <h2 ref="slogan" class="slogan" v-if="!isAuthenticated">
        一个账户，畅享服务
      </h2>

      <!-- 已登录：欢迎语 -->
      <h2 ref="welcome" class="slogan" v-else>
        欢迎回来，{{ displayName }}
      </h2>

      <!-- 未登录按钮组 -->
      <div ref="buttonGroup" class="button-group" v-if="!isAuthenticated">
        <div class="button-container">
          <router-link to="/login">
            <button class="login-btn">登录</button>
          </router-link>
          <router-link to="/register">
            <button class="register-btn">注册</button>
          </router-link>
        </div>
      </div>

      <!-- 已登录按钮组 -->
      <div ref="buttonGroupAuth" class="button-group" v-else>
        <div class="button-container">
          <router-link to="/manage">
            <button class="login-btn">管理我的账户</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { gsap } from 'gsap'

export default {
  name: 'HomePage',
  data() {
    return {
      isLoading: true,
      isAuthenticated: false,
      avatarUrl: '/img/EMlogo-large1x.png',
      displayName: '',
      logoSrc: '/img/EMlogo-large1x.png', // 默认展示 Logo
      logoClass: 'logo'
    }
  },
  async mounted() {
    document.title = "VDS Entertainment Network (Beta版)"
    this.createStarfield()

    // 1. 获取用户信息
    const token = localStorage.getItem('token')
    if (token) {
      try {
        const resp = await axios.get('/userinfo', {
          headers: { 'Authorization': `Bearer ${token}` }
        })
        if (resp.data) {
          this.isAuthenticated = true
          this.avatarUrl = resp.data.avatarUrl || '/img/EMlogo-large1x.png'
          this.displayName = resp.data.nickname || resp.data.username || '用户'
        }
      } catch (err) {
        console.error('获取用户信息失败:', err)
      }
    }

    // 2. 关闭加载动画
    this.isLoading = false

    // 3. 下个渲染周期执行动画
    this.$nextTick(() => {
      this.animatePage()
    })
  },
  methods: {
    createStarfield() {
      const starfield = document.getElementById('starfield')
      if (!starfield) return
      starfield.innerHTML = ''

      // 生成星空
      for (let i = 0; i < 200; i++) {
        const star = document.createElement('div')
        star.className = 'star'
        star.style.top = Math.random() * 100 + 'vh'
        star.style.left = Math.random() * 100 + 'vw'
        starfield.appendChild(star)

        gsap.to(star, {
          opacity: gsap.utils.random(0.4, 1),
          repeat: -1,
          yoyo: true,
          ease: 'power2.inOut',
          duration: gsap.utils.random(1, 3),
          delay: gsap.utils.random(0, 2)
        })
      }
    },

    // 生成波纹圈并播放动画
    createAvatarRipples() {
      // 在头像容器里插入多个圆
      // 你可自行更改圈数、delay、大小、颜色等
      const container = this.$refs.avatarContainer
      if (!container) return

      for (let i = 0; i < 8; i++) {
        const circle = document.createElement('div')
        circle.classList.add('ripple-circle')
        container.appendChild(circle)

        // 用 gsap 做从小到大、从半透明到透明的动画
        // 每个圆延迟 i*0.1s 出现，看起来像一圈一圈向外扩散
        gsap.fromTo(circle,
          { scale: 0.3, opacity: 0.5 },
          {
            scale: 2.2,
            opacity: 0,
            duration: 1,
            ease: 'power2.out',
            delay: i * 0.12,  // 每个圆依次延后一点时间
            onComplete: () => {
              // 动画结束后移除 DOM，避免堆积
              circle.remove()
            }
          }
        )
      }
    },

    animatePage() {
      if (!this.$refs.logoImg) return

      const tl = gsap.timeline()

      // (1) Logo 从下往上淡入
      tl.from(this.$refs.logoImg, {
        y: 50,
        opacity: 0,
        duration: 0.8,
        ease: 'power2.out'
      })

      // 未登录
      if (!this.isAuthenticated) {
        tl.from(
          [this.$refs.slogan, this.$refs.buttonGroup],
          {
            y: 50,
            opacity: 0,
            duration: 0.8,
            ease: 'power2.out',
            stagger: 0.3
          },
          '-=0.3'
        )
      } else {
        // 已登录
        // (2) Logo 放大
        tl.to(this.$refs.logoImg, {
          scale: 1.3,
          duration: 0.3,
          ease: 'power2.out',
          onComplete: () => {
            this.logoSrc = this.avatarUrl
            this.logoClass = 'avatar'
          }
        })

        // (3) Logo 缩回
        // 在 onComplete 回调里生成波纹
        tl.to(this.$refs.logoImg, {
          scale: 1,
          duration: 0.4,
          ease: 'bounce.out',
          onComplete: () => {
            // 此时头像已经出现，开始生成并播放波纹
            this.createAvatarRipples()
          }
        })

        // (4) “欢迎回来”及按钮提前 0.5s 出现
        tl.from(
          [this.$refs.welcome, this.$refs.buttonGroupAuth],
          {
            y: -50,
            opacity: 0,
            duration: 0.8,
            ease: 'power2.out',
            stagger: 0.3
          },
          '-=0.5'
        )
      }
    }
  }
}
</script>

<style scoped>
/* 布局背景 */
.container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: radial-gradient(circle at center, #05001f 0%, #020010 100%);
}

/* 如果你还有星空背景，可以保留或删除 */
#starfield {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
}
.star {
  position: absolute;
  width: 2px;
  height: 2px;
  background-color: #fff;
  border-radius: 50%;
  opacity: 0.7;
}

/* 加载容器 */
.loading-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.spinner {
  width: 50px;
  height: 50px;
  border: 6px solid rgba(255,255,255,0.2);
  border-top: 6px solid #fff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  100% { transform: rotate(360deg); }
}

/* 内容容器 */
.content-container {
  margin-top: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 55px);
}

/* 头像容器：一定要 position: relative; 用来放波纹 */
.avatar-container {
  position: relative; 
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

/* 默认 logo 尺寸 */
.logo {
  height: 50px;
  z-index: 1; /* 确保在波纹之上 */
}

/* 头像 */
.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  z-index: 1; /* 确保在波纹之上 */
}

/* 波纹圆 */
.ripple-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  /* 初始大小跟后面 GSAP scale 的起点匹配 */
  width: 100px; 
  height: 100px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(128, 128, 128, 0.5); /* 半透明灰 */
  pointer-events: none; /* 防止影响点击 */
  z-index: 0; /* 在头像下方 */
}

/* 文字 & 按钮组 */
.slogan {
  margin-top: 20px;
  font-size: 1.7em;
  color: #fff;
  text-shadow: 0 0 10px rgba(255,255,255,0.3);
  z-index: 1;
}
.button-group {
  display: flex;
  gap: 10px;
  z-index: 1;
}
.button-container {
  display: flex;
  justify-content: center;
  padding: 10px;
}
/* 按钮 */
.login-btn,
.register-btn {
  background: #006aff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 50px;
  margin-right: 10px;
  box-shadow: 0 0 10px rgba(0,106,255,0.5);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}
.login-btn:hover,
.register-btn:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(0,106,255,0.7);
}

/* 小屏适配 */
@media (max-width: 768px) {
  .logo {
    height: 40px;
  }
  .slogan {
    font-size: 1.2em;
    text-align: center;
  }
  .avatar {
    width: 80px;
    height: 80px;
  }
}
</style>