<template>
    <div id="global-navbar">
        <div id="navbar-background"></div>
        <router-link to="/">
            <img style="height: 20px;" src="/img/EMlogo-large1x.png" alt="VDS Entertainment Network 的 Logo" />
        </router-link>
    </div>
</template>

<script>
export default {
    name: "vc-nav",
    data() {
        return {
            isLive: false, // 状态初始化为 false
        };
    },
    mounted() {
        this.initializeNavbar();
    },
    methods: {
        initializeNavbar() {
            const navbar = document.getElementById('global-navbar');
            const navItems = document.querySelectorAll('.nav-item');
            let closeTimeout;

            // 为导航栏中的每个菜单项添加事件监听器
            navItems.forEach(item => {
                const link = item.querySelector('a');
                const submenu = item.querySelector('.submenu');

                // 鼠标悬停时展开子菜单
                link.addEventListener('mouseover', () => {
                    clearTimeout(closeTimeout);
                    navbar.classList.add('expanded');
                    this.hideAllSubmenus();
                    submenu.classList.add('show');
                    submenu.querySelectorAll('.submenu-category').forEach(category => {
                        Array.from(category.querySelectorAll('a')).forEach((child, index) => {
                            child.style.animationDelay = `${index * 0.06}s`;
                        });
                    });
                });

                // 鼠标离开菜单项时延迟关闭子菜单
                item.addEventListener('mouseleave', () => {
                    closeTimeout = setTimeout(() => {
                        if (!document.querySelector('.nav-item:hover') && !document.querySelector('.submenu:hover')) {
                            navbar.classList.remove('expanded');
                            this.hideAllSubmenus();
                        }
                    }, 300);
                });

                // 鼠标悬停在子菜单时取消关闭计时器
                submenu.addEventListener('mouseover', () => {
                    clearTimeout(closeTimeout);
                });

                // 鼠标离开子菜单时延迟关闭子菜单
                submenu.addEventListener('mouseleave', () => {
                    closeTimeout = setTimeout(() => {
                        if (!document.querySelector('.nav-item:hover') && !document.querySelector('.submenu:hover')) {
                            navbar.classList.remove('expanded');
                            this.hideAllSubmenus();
                        }
                    }, 300);
                });
            });

            // 点击页面其他地方时关闭所有子菜单
            document.addEventListener('click', event => {
                if (!navbar.contains(event.target)) {
                    this.hideAllSubmenus();
                    navbar.classList.remove('expanded');
                }
            });

            // 添加对 router-link 点击的监听器，导航成功后收起菜单并滚动到顶部
            this.$router.afterEach(() => {
                this.hideAllSubmenus();
                navbar.classList.remove('expanded');
                window.scrollTo(0, 0); // 滚动到页面顶部
            });
        },
        hideAllSubmenus() {
            // 隐藏所有子菜单并清除动画延迟
            document.querySelectorAll('.submenu').forEach(sub => {
                sub.classList.remove('show');
                sub.querySelectorAll('a').forEach(child => {
                    child.style.animationDelay = '';
                });
            });
        }
    }
};
</script>

<style scoped>
/* VC NAV CSS Ver1.0.0 20240731 VDS Cultural Media */

.live-badge {
  background-color: rgb(255, 73, 73);
  color: white;
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 8px;
  margin-left: 2px;
}

body {
    margin: 0;
}

#global-navbar {
    font-family: 'Misans-light', Arial, sans-serif;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: 1000;
    /* 保证导航栏在最前 */
    display: flex;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
}

#navbar-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: rgba(34, 34, 34, 0.4);
    backdrop-filter: blur(50px);
    z-index: -1;
    /* 放在最底层 */
    transition: height 0.3s ease;
    /* 只过渡高度 */
}

#global-navbar.expanded #navbar-background {
    height: 100vh;
    /* 悬浮时背景展开到全屏高度 */
}

#navbar-background::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    opacity: 0;
    transition: opacity 0.3s ease;
    /* 过渡透明度 */
}

#global-navbar.expanded #navbar-background::before {
    opacity: 1;
}

#global-navbar svg {
    height: 40px;
    /* 调整logo高度 */
    fill: white;
}

#nav-links {
    font-family: 'MiSans', Arial, sans-serif;
    display: flex;
    gap: 25px;
    /* 导航选项间距 */
    flex-grow: 1;
    justify-content: center;
    z-index: 1;
    /* 确保在背景之上 */
}

#nav-links a {
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: bold;
    position: relative;
}

/* 取消文字的划线效果 */
#nav-links a::after {
    display: none;
}

/* 子菜单样式 */
.submenu {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    display: flex;
    /* 改为水平排列 */
    flex-direction: row;
    /* 改为水平排列 */
    gap: 30px;
    /* 类与类之间的间距 */
    padding: 20px 15%;
    box-sizing: border-box;
    z-index: 999;
    /* 确保在global-navbar下面一层 */
    opacity: 0;
    pointer-events: none;
    /* 初始不可点击 */
    transform: translateY(-20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.submenu-category {
    display: flex;
    flex-direction: column;
}

.submenu-category p {
    font-family: 'MiSans-Light', Arial, sans-serif;
    color: grey;
    margin: 0 0 15px 0;
}

.submenu.show {
    display: flex;
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
}

.submenu a {
    color: white;
    font-size: 14px;
    text-decoration: none;
    opacity: 0;
    margin: 0 0 8px 0;
    transform: translateY(-20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.submenu.show a {
    animation: fadeInDown 0.2s forwards;
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-8px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* 大号屏幕样式 */
@media (min-width: 1024px) {
    #global-navbar {
        padding-left: 15%;
        padding-right: 15%;
    }

    .submenu {
        padding-left: 15%;
        padding-right: 15%;
    }
}

/* 中号屏幕样式 */
@media (min-width: 768px) and (max-width: 1023px) {
    #global-navbar {
        padding-left: 20px;
        padding-right: 20px;
    }
}

/* 小号屏幕样式 */
@media (max-width: 767px) {
    #global-navbar {
        padding-left: 20px;
        padding-right: 20px;
        transition: height 0.3s ease; /* 为手机视图添加过渡效果，减少抽搐 */
    }

    #nav-links {
        justify-content: flex-end;
    }

    .submenu {
        flex-direction: column;
        /* 纵向排列 */
        padding-left: 20px;
        padding-right: 20px;
    }

    .submenu-category p,
    .submenu a {
        font-size: 18px;
        /* 增大字号 */
    }
}
</style>